<template>
  <component :is="type" :style="styleObj" class="ui-icon" :class="classObj">
    <component
      :is="currentSVG"
      :color="props.color"
      :filled
      class="ui-icon__main"
    />
  </component>
</template>
<script setup lang="ts">
import { iconProps } from './icon'

/**
 * Icon component for displaying SVG icons.
 * @notice can't use  dynamic names with src of img , reference: // https://github.com/nuxt/nuxt/issues/14766
 * @example
 * <template>
 *   <ui-icon :svg="SVGIconMenuAlert" />
 * </template>
 *
 * import SVGIconMenuAlert from '@base/assets/img/svg/icon-alert-new.svg'
 */

// [ Props]
const props = defineProps(iconProps)
const clickable = toRef(() => props.type === 'button')
/** use icon's original colors */
const filled = toRef(() => !props.color)
const size = useCssUnitFormatter(() => props.size)

//  [ Show SVG ]
const currentSVG = toRef(() => props.svg)

// [ Class ]
const classObj = computed(() => {
  return {
    'is-clickable': !props.disabled && clickable.value,
    'is-disabled': props.disabled,
  }
})
// [ Style ]
const styleObj = computed(() => {
  return {
    '--ui-icon-size': size.value,
  }
})
</script>
<style lang="postcss" scoped>
.ui-icon {
  --ui-icon-hover-color: var(--brand-color-text-secondary);
  --ui-icon-active-color: var(--brand-color-text-primary);
  --ui-icon-disabled-color: var(--brand-color-base-secondary);
  all: unset;
  display: inline-flex;
  align-items: center;

  &.is-clickable {
    cursor: pointer;
    &:hover :deep(svg) {
      color: var(--ui-icon-hover-color);
      & path {
        fill: currentColor;
      }
    }
    &:focus-visible,
    &:active {
      & :deep(svg) {
        color: var(--ui-icon-active-color);
        & path {
          fill: currentColor;
        }
      }
    }
  }

  &.is-disabled {
    &:deep(svg) {
      color: var(--ui-icon-disabled-color);
      & * {
        fill: currentColor;
      }
    }
  }

  & .ui-icon__main {
    font-size: var(--ui-icon-size);
  }

  /* reset nuxt-icon style */
  & :deep(.nuxt-icon) {
    margin-bottom: 0;
  }
}
</style>
