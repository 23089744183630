import type { ExtractPublicPropTypes, PropType, ExtractPropTypes } from 'vue'

export const iconProps = {
  /**
   * svg component
   */
  svg: {
    type: Object as PropType<Component>,
    required: true,
  },
  /**
   * The size of svg
   * @default 24
   */
  size: {
    type: [String, Number],
    default: 24,
  },
  /**
   * color of svg
   * @notice When setting color, other states are invalid, eg:'hover','press'
   */
  color: {
    type: String,
  },
  /**
   * Set the root element type of the icon, which will bring different styles
   */
  type: {
    type: String as PropType<'i' | 'button'>,
    default: 'i',
  },
  /**
   * @notice when setting disabled, clickable is invalid
   */
  disabled: {
    type: Boolean,
    default: false,
  },
}
export type IconProps = ExtractPropTypes<typeof iconProps>
export type IconPublicProps = ExtractPublicPropTypes<typeof iconProps>
