/**
 * Options for the CSS unit formatter.
 *
 * @typedef {Object} UseCssUnitFormatterUnitOptions
 * @property {string} [unit] - The CSS unit to use for formatting (default is 'px').
 */
export interface UseCssUnitFormatterUnitOptions {
  unit?: string
}

/**
 * CSS unit formatter.
 *
 * @param {MaybeRefOrGetter<number | string>} size - The size value to be formatted, can be a number or a string.
 * @param {UseCssUnitFormatterUnitOptions} [options] - Formatting options, including the unit (default is 'px').
 * @returns {ComputedRef<string>} Returns a computed property that is the formatted CSS unit string.
 *
 * @example
 * const formattedSize = useCssUnitFormatter(20); // Returns "20px"
 * const formattedSize = useCssUnitFormatter('20'); // Returns "20px"
 * const formattedSizeWithUnit = useCssUnitFormatter('20em'); // Returns "20em"
 * const formattedSizeWithOptions = useCssUnitFormatter(20, { unit: 'em' }); // Returns "20em"
 */
export function useCssUnitFormatter(
  size: MaybeRefOrGetter<number | string>,
  options?: UseCssUnitFormatterUnitOptions,
) {
  return computed(() => {
    const unit = options?.unit || 'px'
    const sizeValue = toValue(size)
    if (!Number.isNaN(Number(sizeValue))) {
      return `${sizeValue}${unit}`
    }
    return sizeValue
  })
}
